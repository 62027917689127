// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-products-js": () => import("./../src/templates/Products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancelar-js": () => import("./../src/pages/cancelar.js" /* webpackChunkName: "component---src-pages-cancelar-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-gracias-js": () => import("./../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

