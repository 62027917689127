import React ,{ createContext, useState, useEffect  } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) =>{
    //localStorage.removeItem('dataCart');
    const [key, setKey] = useState([])
    const [ cart , setCart ] = useState(key);

    const addToCart = (element , inStorage) => { 

        setCart([ ...cart, element ]);

        if( inStorage ){
            localStorage.setItem('dataCart', JSON.stringify( [ ...cart, element ] ) );
        }
    };

    const delToCart = ( element )=>{

        const uuid = element.uuid;
        const result = cart.filter( item => item.uuid !== uuid );
        setCart([ ...result ]);
        localStorage.setItem('dataCart', JSON.stringify( [ ...result ] ) );
        console.log('delete::::',result);
    }

    useEffect(() => {

        setKey(localStorage.getItem('token'));
        const dataCart     = localStorage.getItem('dataCart');
        const dataCartJSON = JSON.parse(dataCart);
        const key = dataCartJSON||[];
        setCart(key);
      }, [])
    
    return (

        <CartContext.Provider value={{
            cart,
            addToCart,
            delToCart,
        }}>
            { children }
        </CartContext.Provider>
    )
}

export const storeToCart = ()=>{

    const dataCart = localStorage.getItem('dataCart');
    const dataCartJSON = JSON.parse(dataCart);
    //console.log('entry store to cart:::::', dataCartJSON );
}